<template>
  <v-container v-if="authUser && product && product.id" id="donation">
    <my-wallet v-if="false" />

    <div v-if="false" class="title text-center my-4">
      <v-avatar size="32" class="" contain>
        <v-img src="/img/coins/coins-150.png"></v-img>
      </v-avatar>
      {{ isHindi ? `कॉइन स्टोर` : `Coin Store` }}
    </div>

    <v-row class="justify-center">
      <v-col cols="12" md="6" class="text-center">
        <v-avatar class="ma-3" size="125" tile>
          <v-img src="/img/coins/coins-150.png"></v-img>
        </v-avatar>
        <product-coins :product="product" />

        <v-card-title class="my-4 display-2 text-center justify-center">
          {{
            isHindi ? `मात्र ₹${product.amount}/-` : `₹${product.amount}/- only`
          }}
        </v-card-title>
        <v-card>
          {{ paymentStatus }}
        </v-card>
        <CheckboxRefundAndTerms :ctx="this" />
        <v-card-actions>
          <v-btn
            :loading="loading && paymentStatus"
            :disabled="loading"
            class="green darken-2 white--text anim-shine"
            @click="() => handleBtnClick(product)"
            rounded
            x-large
            block
          >
            {{ $lang("purchase") }}
          </v-btn>
        </v-card-actions>
        <v-card-actions class="mt-8">
          <v-btn
            :loading="loading && paymentStatus"
            :disabled="loading"
            class=""
            @click="
              () => {
                $router.go(-1);
              }
            "
            rounded
            text
            x-large
            block
          >
            <v-icon left>mdi-arrow-left</v-icon>
            {{ $lang("back") }}
          </v-btn>
        </v-card-actions>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import { axios } from "@/plugins/axios";
import bus from "@/bus";
import { handleClick } from "../modules/payment.products";
import MyWallet from "../modules/MyWallet.vue";
import CheckboxRefundAndTerms from "../modules/CheckboxRefundAndTerms.vue";
import ProductCoins from "../modules/ProductCoins.vue";

export default {
  name: "product",
  components: {
    MyWallet,
    CheckboxRefundAndTerms,
    ProductCoins,
  },

  data() {
    return {
      loading: false,
      product: {},
      paymentStatus: null,
      paymentMetadata: null,
      agree: false,
    };
  },
  computed: {
    ...mapGetters({
      authUser: "auth/user",
    }),

    isHindi() {
      return this.$store.getters.getCurrLocale == "hi";
    },
  },
  watch: {
    paymentStatus(val) {
      if (!val) {
        return;
      }
      bus.$emit("showWait");
      this.loading = true;

      if (val == "failed") {
        this.fetchOrder(this.paymentMetadata.order_id);
      }
      if (val == "success") {
        this.$router.replace({
          name: "invoice",
          params: { id: this.paymentMetadata.order_uid },
        });
      }
    },
  },
  methods: {
    fetchOrder(id) {
      bus.$emit("showWait");
      return axios
        .get("payments/order/" + id)
        .then((res) => {
          console.log("order", res.data);
          this.$router.replace({
            name: "invoice",
            params: { id: res.data.uid },
          });
          window.location.replace("/invoice/" + res.data.uid);
        })
        .catch(() => {
          this.$router.replace({ name: "products" });
        })
        .finally(() => {
          bus.$emit("hideWait");
        });
    },
    fetchProduct(id) {
      bus.$emit("showWait");
      return axios
        .get("products/public/" + id)
        .then((res) => {
          this.product = res.data;
        })
        .finally(() => {
          bus.$emit("hideWait");
        });
    },
    handleBtnClick(product) {
      this.loading = true;
      if (!this.agree) {
        this.agree = true;
        this.$store.dispatch("setAlert", {
          message: "You must agree to the terms to proceed further.",
          type: "warning white--text",
        });
        setTimeout(() => {
          this.agree = false;
          this.loading = false;
        }, 200);
        return;
      }
      if (this.authUser && product && product.amount) {
        bus.$emit("showWait");
        handleClick(
          {
            user: this.authUser,
            product_id: product.id,
            quantity: 1,
          },
          this
        )
          .catch((err) => {
            console.error(err);
          })
          .finally(() => {
            this.loading = false;
            bus.$emit("hideWait");
          });
      }
    },
  },
  created() {
    this.fetchProduct(this.$route.params.id);
  },
};
</script>

