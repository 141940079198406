<template>
  <v-card class="rounded-lg" elevation="0" outlined>
    <v-card-title class="px-2">
      <v-avatar size="24" class="mr-2" contain>
        <v-img src="/img/coins/coins-150.png"></v-img>
      </v-avatar>
      {{ $lang("coins") }}
      <v-spacer></v-spacer>
      {{ product.vcoin + product.gcoin }}
      {{ $lang("coins") }}
    </v-card-title>
    <v-divider></v-divider>

    <v-card-actions>
      <v-avatar size="24" class="mr-2" contain>
        <v-img src="/img/coins/coin-single-50.png"></v-img>
      </v-avatar>
      {{ $lang("vCoin") }}
      <v-spacer></v-spacer>
      {{ product.vcoin }}
      {{ $lang("coins") }}
    </v-card-actions>
    <v-divider></v-divider>
    <v-card-actions>
      <v-avatar size="24" class="mr-2" contain>
        <v-img src="/img/coins/coin-single-50.png"></v-img>
      </v-avatar>
      {{ $lang("gCoin") }}
      <v-spacer></v-spacer>
      {{ product.gcoin }}
      {{ $lang("coins") }}
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "product-coins",
  components: {},
  props: ["product"],
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      authUser: "auth/user",
    }),

    isHindi() {
      return this.$store.getters.getCurrLocale == "hi";
    },
  },
  watch: {},
  methods: {},
  created() {},
};
</script>
